<template>
  <div class="sig-editor-lite">
    <div class="action row align-items-center pb-3">
      <div class="col-auto">
        <el-button type="primary" @click="appendTitle">添加标题</el-button>
        <!-- <el-button type="success" @click="e => appendImage()">添加图片</el-button> -->
      </div>
      <div class="col"></div>
      <div class="col-auto">
        <!-- <el-button type="primary" @click="isDialogVisible = true">预览</el-button> -->
      </div>
    </div>

    <sig-editor ref="editor" :value="value2" :disable-toolbar="true" @change="valueChange" />

    <el-dialog :visible.sync="isDialogVisible" custom-class="sig-editor-lite-dialog">
      <div class="preview-container" v-html="value2"></div>
    </el-dialog>
  </div>
</template>

<script>
import { Component, Vue, Prop, Model, Watch } from 'vue-property-decorator';
import SigEditor from '../sig-editor/sig-editor.vue';

export default @Component({
  name: 'sig-editor-lite',
  components: {
    SigEditor,
  }
})
class SigEditorLite extends Vue {
  @Model('change') value;
  isDialogVisible = false;
  value2 = '';

  mounted () {
    this.value2 = this.value;
  }

  valueChange (value) {
    this.$emit('change', value);
    this.$emit('update:value', value);
    console.log(value, 111111111);
    this.value2 = value;
  }

  appendTitle () {
    this.$refs.editor.append('<h2>标题</h2><br/>')
  }

  appendImage () {
    this.$refs.editor.selectImage();
  }
}
</script>

<style lang="scss">
.sig-editor-lite {
  .el-button--success {
    background: #3471CD;
  }
}

.sig-editor-lite-dialog {
  .preview-container {
    overflow-y: scroll;
    max-height: 80vh;

    p {
      margin: 0;
      font-size: 14px;
      height: 20px;
    }
  }
}
</style>
