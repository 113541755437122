`
<template>
  <div class="sig-editor" :class="{ disable_toolbar: disableToolbar }">
    <div class="sig-editor-container" ref="container"></div>
    <el-upload ref="upload" :action="uploadImgServer" :file-list="fileList" :on-change="uploadChange"
      :on-success="uploadSuccess" :on-error="uploadError" />
  </div>
</template>

<script>
import { Component, Vue, Prop, Model, Watch } from 'vue-property-decorator';
import WEditor from 'wangeditor';
import * as api from "./sig-editor.api";

export default @Component({ name: 'sig-editor' })
class SigEditor extends Vue {
  @Model('change') value;
  @Prop({ default: false }) disableToolbar;
  @Prop({ default: true }) autofocus;
  @Prop({ default: 0 }) height;
  @Prop({ default: '' }) placeholder;
  @Prop({ default: null }) menus;
  @Prop({ default: null }) colors;
  @Prop({ default: null }) fontSizes;
  @Prop({ default: null }) lineHeights;
  @Prop({ default: null }) emotions;
  @Prop({ default: api.getUploadApiUrl() }) uploadImgServer;
  @Prop({ default: 0 }) uploadImgMaxSize;
  @Prop({ default: 1 }) uploadImgMaxLength;
  @Prop({ default: 0 }) uploadImgTimeout;
  @Prop({ default: false }) disabled;

  fileList = [];
  insertImgFn = function () {
  };

  @Watch('disabled')
  disabledChange (disabled) {
    if (disabled) {
      this.editor.disable();
    } else {
      this.editor.enable();
    }
  }

  @Watch('value')
  valueChanged (value) {
    if (!this.editor.txt.html()) {
      this.editor.txt.html(value);
    }
  }

  mounted () {
    const { container } = this.$refs;
    this.editor = new WEditor(container);

    [
      'height',
      'placeholder',
      'menus',
      'colors',
      'fontSizes',
      'lineHeights',
      'emotions',
      'uploadImgServer',
      'uploadImgMaxSize',
      'uploadImgMaxLength',
      'uploadImgTimeout',
    ].forEach(key => {
      if (this[key]) {
        this.editor.config[key] = this[key];
      }
    });

    this.editor.config.zIndex = 1;
    this.editor.config.focus = this.autofocus;
    this.editor.config.onchange = newHtml => {
      this.$emit('change', newHtml);
      this.$emit('update:value', newHtml);
    };
    this.editor.config.onblur = newHtml => this.$emit('blur', newHtml);
    this.editor.config.onfocus = newHtml => this.$emit('focus', newHtml);
    this.editor.config.customAlert = s => this.$confirm(s);
    this.editor.config.customUploadImg = (resultFiles, insertImgFn) => {
      this.insertImgFn = insertImgFn;
      this.uploadEditorImage(resultFiles[0]);
    };
    this.editor.config.showLinkImg = false;

    this.editor.create();
  }

  append (html) {
    if (this.editor.txt.html()) {
      this.editor.txt.append(html);
    } else {
      this.editor.txt.html(html);
    }
    this.scrollToBottom();
  }

  scrollToBottom () {
    const { node } = this.editor.change;
    for (let i = node.children.length; i >= 0; i--) {
      const lastNode = node.children[i - 1];
      if (lastNode.localName !== 'br') {
        node.scrollTop = lastNode.offsetTop + lastNode.clientHeight;
        break;
      }
    }
  }

  scrollToTop () {
    const { node } = this.editor.change;
    node.scrollTop = 0;
  }

  selectImage () {
    for (const item of this.editor.menus.menuList) {
      if (item.key === 'image') {
        item.createPanel();
        setTimeout(() => {
          const input = document.querySelector(`.w-e-panel-tab-content input`);
          input.click();
          console.log(input);
        }, 200);
        break;
      }
    }
  }

  uploadChange (file, fileList) {
    this.fileList = fileList;
  }

  uploadSuccess (response, file, fileList) {
    if (this.insertImgFn) {
      this.insertImgFn(response.url);
      setTimeout(() => {
        this.scrollToBottom();
      }, 100);
    }
  }

  uploadError (err, file, fileList) {
    this.$message.error('文件上传失败: ' + JSON.stringify(err));
  }

  uploadEditorImage (file) {
    console.log(111111111111);
    this.$refs.upload.uploadFiles.push(file);
    this.$refs.upload.$refs['upload-inner'].upload(file);
  }
}
</script>

<style lang="scss">
.sig-editor {
  &.disable_toolbar {
    .w-e-toolbar {
      // display: none;
    }
  }

  .w-e-text-container {
    overflow-y: scroll !important;
    border-top: 1px solid #c9d8db !important;
    border-radius: 8px;

    p {
      margin: 0;
    }
  }
}
</style>
`
