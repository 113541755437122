/**
 * 活动添加相关API
 */

import request from '@/utils/request'

/**
 * 添加活动
 * @param data
 */
export function activeGoodsActivityAdd(data) {
  return request({
    url: '/active/goodsActivity/add',
    method: 'post',
    headers: { "Content-Type": "application/json" },
    data
  })
}

/**
 * 活动编辑回显
 * @param id
 */
export function activeGoodsActivityShow(id) {
  return request({
    url: `/active/goodsActivity/${id}`,
    method: 'get'
  }).then(resp => {
    resp.info = resp.intro;
    return resp;
  });
}

/**
 * 活动编辑提交
 * @param data
 */
export function activeGoodsActivityEdit(data) {
  return request({
    url: '/active/goodsActivity/edit',
    method: 'post',
    headers: { "Content-Type": "application/json" },
    data
  })
}

/**
 * 活动编辑提交
 */
export function editTicketDate(params) {
  return request({
    url: '/active/goodsActivity/editTicketDate',
    method: 'post',
    // headers: { "Content-Type": "application/json" },
    params,
  })
}
